import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0095 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0002/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0072/'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0068/'
  )
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )
  const routeParam5 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam6 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理と労務管理の違いとは？システムで連携させるメリットを紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.04.18</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0095.jpg"
          alt="キンクラコラム画像95"
        />
        <p>
          勤怠管理と労務管理は混同されやすい業務ですが、それぞれの業務内容には違いがあります。では、具体的にどのような違いがあるのか？この記事では、勤怠管理と労務管理の違いや特徴、それぞれをシステムで連携させることによるメリットについて解説します。{' '}
        </p>
        <div id="toc1">
          <h1>勤怠管理と労務管理の違いとは</h1>
        </div>
        <img
          src="/images/column/details/c0095_g01.jpg"
          alt="キンクラコラム画像95"
        />

        <p>勤怠管理と労務管理にはそれぞれ、以下のような違いがあります。</p>

        <div id="toc1-1">
          <h2>勤怠管理とは</h2>
        </div>
        <p>
          勤怠管理とは、主に従業員の労働時間を管理することを指します。
          <br />
          企業は従業員の出退勤時間や休憩、休日などの労働時間を管理する責任があります。従業員の労働時間を適切に把握することは、働き過ぎによる従業員の健康被害を防ぐために重要です。また、勤怠管理は従業員の働き方や給与計算にも関わるため、労務管理の中でも特に重要度が高いといえるでしょう。
        </p>
        <a href={routeParam} className="column-detail-link">
          関連記事：勤怠管理とは？管理をする必要性や管理体制の整備について解説
        </a>

        <div id="toc1-2">
          <h2>労務管理とは</h2>
        </div>
        <p>
          労務管理とは、従業員の労働に関する様々な事項を管理することを指します。具体的には、雇用契約書や就業規則の作成、勤怠管理、給与計算などその範囲は多岐にわたります。
          <br />
          上述のように、勤怠管理は労務管理の一部ということです。労務管理を実施する目的は主に二つあります。
          <br />
          一つは「法令順守とリスク回避」です。企業は労働基準法の定めのもと、従業員の勤怠状況や労働環境の整備、人材の配置や給与の支払いなどさまざまな対応をしなければなりません。これらを疎かにしてしまうと法令違反による罰則を受けるといったリスクが発生します。
          <br />
          もう一つは「生産性の向上」です。労働環境の改善や従業員の健康維持を適切に行うことによって生産性の向上につながります。
        </p>

        <div id="toc2">
          <h1>労務管理のためには勤怠管理が必須！その理由とは</h1>
        </div>
        <p>
          適切な労務管理のためには、勤怠管理をしっかりと行う必要があります。
          <br />
          ここからは、その理由について考えていきましょう。
        </p>

        <div id="toc2-1">
          <h2>従業員の給与を正しく計算するため</h2>
        </div>
        <p>
          <p>
            従業員の給与を計算するためには正確な勤怠管理が欠かせません。
            <br />
            出退勤や勤務時間を精確に把握しなければならず、企業が支払う給与の中には基本給だけでなく、残業代や休日出勤手当も含まれています。残業代や各種手当の支給額は状況により異なるため、それぞれを正確に計算する必要があるのです。そのため、適正な給与計算を実施するには、精確な勤怠管理が求められます。
          </p>
        </p>
        <a href={routeParam2} className="column-detail-link">
          関連記事：有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介
        </a>

        <div id="toc2-2">
          <h2>コンプライアンスを遵守するため</h2>
        </div>
        <p>
          近年、法令に反する長時間労働などのブラックな働き方が社会問題として注目されています。
          <br />
          企業が従業員を雇用する際には、労働基準法をはじめとした法令を順守することが重要です。勤怠管理がおろそかになると「コンプライアンスに反するブラック企業」というレッテルが貼られ、企業のイメージが損なわれるリスクもあります。そのため、労務管理の一環として適切な勤怠管理を行うことは、法令遵守につながります。
        </p>
        <a href={routeParam3} className="column-detail-link">
          関連記事：勤怠管理システムを導入していない会社で発生するリスクとは？
        </a>

        <div id="toc2-3">
          <h2>従業員を適切に管理するため</h2>
        </div>
        <p>
          長時間労働は従業員の心身の健康に大きなリスクをもたらします。労務管理が不十分な場合、従業員が体調を崩し、休職や退職を余儀なくされることもあります。労務管理のトラブルは訴訟や、過労死などの重大な労働問題につながることもあるため、注意が必要です。
          <br />
          適切な勤怠管理を行うことで、労務管理の問題を早期に発見し、従業員の心身の健康を守ることができます。
        </p>

        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}

        <div id="toc3">
          <h1>システムによる勤怠管理と労務管理を連携させるメリット</h1>
        </div>
        <img
          src="/images/column/details/c0095_g02.jpg"
          alt="キンクラコラム画像95"
        />
        <p>
          システムを使って勤怠管理と労務管理を連携させることで、様々なメリットが得られます。どのようなメリットがあるのか解説します。
        </p>

        <div id="toc3-1">
          <h2>給与計算を効率化できる</h2>
        </div>
        <p>
          勤怠管理と労務管理を連携させることで、給与計算を効率化できます。給与計算では、従業員の勤務時間や勤務日数、雇用形態に応じて残業代や手当の計算が必要です。これらの情報を別々に管理をしていると、情報の確認や集計に手間がかかります。
          <br />
          しかし、勤怠管理と労務管理を連携させることで、自動的にデータを集計してくれるため、給与計算の業務を効率化することが可能になります。
        </p>
        <a href={routeParam4} className="column-detail-link">
          関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>

        <div id="toc3-2">
          <h2>社員の入社や退職の情報をシステムに即時反映できる</h2>
        </div>
        <p>
          社員の入社や退職といった人事変動が生じた際、その情報を勤怠管理システムにすぐに反映させることが重要です。情報が正確に反映されないと、給与計算などに大きな影響を与えてしまうからです。
          <br />
          このようなミスを防ぐにはは労務管理と勤怠管理を連携させることが役立ちます。また、それぞれのシステムが独立している場合、情報を更新するのに手間がかかりますが、システムを連携することによってスムーズに情報の更新が可能なため、ここでも業務を効率化できるというメリットもあります。
        </p>

        <div id="toc3-3">
          <h2>過重労働の防止につながる</h2>
        </div>
        <p>
          企業が労務管理システムを活用し、過重労働対策を行う動きが増えています。労務管理システムには、従業員の勤務時間や労働状況をデータ化する機能が備わっています。システムに記録されたデータをチェックすることで、労務管理の問題を即座に把握可能です。
          <br />
          過重労働は従業員の心身の健康を損なうだけでなく、企業が罰則の対象となるケースもあります。システムのデータをもとに過重労働が起きていないかを確認し、問題があるときにはすぐに是正対策を行いましょう。
        </p>

        <div id="toc3-4">
          <h2>集計のミスや不正を防ぐことができる</h2>
        </div>
        <p>
          タイムカードを使ったアナログな集計のには、ヒューマンエラーが起こりやすいという問題があります。
          <br />
          人の手で集計をすると、どんなに注意してても計算ミスや集計ミスが起こってしまいます。さらに、従業員によるタイムカード打刻の不正が起きやすいのも、アナログな集計の問題点です。また、従業員が多ければ多いほど、管理者の負担が大きくなりミスが増える要因にもなります。しかし、労務管理と勤怠管理を連携すれば、管理者の負担が減るため、ミスを減らすことが出来ます。
        </p>
        <a href={routeParam5} className="column-detail-link">
          関連記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
        </a>
        <p>
          このように、勤怠管理と労務管理を連携させることで様々なメリットが得られます。
        </p>

        <div id="toc4">
          <h1>勤怠管理・労務管理を効率化するなら「キンクラ」</h1>
        </div>
        <p>
          この記事では、勤怠管理と労務管理の違いや、システムによる連携のメリットを解説してきました。システムによって連携させることによって、自動化による効率化や自動化によるミスの防止をすることが可能です。
          <br />
          とはいえ、システムを導入しようにもどのようなシステムを導入すればいいのか分からないという方も多いかと思います。
          <br />
          そこでおすすめなのが「キンクラ」です。「キンクラ」とはクラウド型の勤怠管理システムです。「キンクラ」を導入することによって、勤怠管理・労務管理を効率化することができます。また、「キンクラ」は全機能を無料でお試しできるトライアルがあります。そのため、実際に導入して使用感を確かめることが可能です。もし、勤怠管理・労務管理の効率化を実現するならば「キンクラ」を導入してみませんか？
        </p>

        <a href={routeParam6} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0095
